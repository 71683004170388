import type { IpAddressModifyBody } from "@warrenio/api-spec/spec.oats.gen";
import { Badge } from "../../../components/Badge.tsx";
import { ClipBoardTooltip } from "../../../components/ClipBoardTooltip.tsx";
import { ContentPane } from "../../../components/ContentPane.tsx";
import { NavigateAfterDelete } from "../../../components/NavigateAfterDelete.tsx";
import { ResourceWithIcon } from "../../../components/ResourceWithIcon.tsx";
import { ViewTitle } from "../../../components/Title.tsx";
import { Toolbar } from "../../../components/Toolbar.tsx";
import { LongDate } from "../../../components/l10n/DateFormat.tsx";
import { DeleteModal, UnAssignModal } from "../../../components/modal/DeleteModal.tsx";
import { DetailsHolder, DetailsTable } from "../../../components/table/DetailsTable.tsx";
import { DetailsTableBillingAccount } from "../../../components/table/DetailsTableBillingAccount.tsx";
import { MonthlyCostRow } from "../../../components/table/DetailsTableMonthlyCost.tsx";
import { DetailsTableName } from "../../../components/table/DetailsTableName.tsx";
import { DetailsTableRow } from "../../../components/table/DetailsTableRow.tsx";
import { TODO } from "../../../dev/Todo.tsx";
import { useDeletableResource } from "../../../utils/query/useDeletableResource.tsx";
import { useSuspenseQueryAtom } from "../../../utils/query/useSuspenseQueryAtom.ts";
import { getResourceById } from "../../api/resourceTypeException.ts";
import { useStandardMutation } from "../../api/useStandardMutation.ts";
import { LocationDetail } from "../../location/LocationDetail.tsx";
import { AssignToSelect } from "../AssignToSelect.tsx";
import {
    assignIpAddressMutation,
    deleteIpAddressMutation,
    modifyIpAddressMutation,
    unassignIpAddressMutation,
} from "./apiOperations.ts";
import { assignedIpsQueryAtom } from "./joinAssignedQuery.ts";
import {
    UNASSIGNED_RESOURCE,
    getAddressFromResource,
    getAssignedResourceId,
    resourceIdToString,
    type IpAssignableResource,
} from "./resourceId.ts";

export function IpAddressView({ location, addressId }: { location: string; addressId: string }) {
    //#region Hooks
    const modifyMutation = useStandardMutation(modifyIpAddressMutation);
    const assignMutation = useStandardMutation(assignIpAddressMutation);
    const unassignMutation = useStandardMutation(unassignIpAddressMutation);
    const deleteMutation = useStandardMutation(deleteIpAddressMutation);

    const data = useSuspenseQueryAtom(assignedIpsQueryAtom(location));

    const obj = useDeletableResource(() => getResourceById(data, addressId, "ip_address"), deleteMutation);
    //#endregion

    if (obj === undefined) {
        return <NavigateAfterDelete />;
    }

    const { $type, address, billing_account_id, created_at, name } = obj;
    const assignedResourceId = getAssignedResourceId(obj);

    async function onDelete() {
        await deleteMutation.mutateAsync({
            location,
            address,
        });
    }

    async function onModify(body: IpAddressModifyBody) {
        await modifyMutation.mutateAsync({
            body,
            location,
            address,
        });
    }

    async function unAssign() {
        await unassignMutation.mutateAsync({
            location,
            address,
        });
    }

    async function assignTo(value: IpAssignableResource | UNASSIGNED_RESOURCE) {
        const private_ip = getAddressFromResource(value);
        if (private_ip) {
            await assignMutation.mutateAsync({
                body: {
                    private_ip,
                },
                location,
                address,
            });
        } else {
            await unAssign();
        }
    }

    return (
        <>
            <Toolbar>
                <UnAssignModal
                    title="Unassign Ip Address"
                    isDisabled={!assignedResourceId}
                    // TODO: disable show modal/button hint when isDisabled?
                    modalAction={async () => await unAssign()}
                >
                    Removing the public IPv4 address assignment from this resource will make the resource inaccessible
                    from the public Internet. The IP address will remain reserved to your account. You can manage public
                    IPv4 addresses from the Network menu on the left sidebar. When reserved but not assigned to a
                    resource IPv4 address will be charged for <TODO>get me</TODO> 3,9 € / month.
                </UnAssignModal>
                <DeleteModal title="Delete Ip Address" modalAction={onDelete}>
                    Deleting the floating IP address "{name || address}" will remove it from your account and release it
                    to the public pool.
                </DeleteModal>
            </Toolbar>

            <ContentPane>
                <ViewTitle title={name ? name : address} />

                <DetailsHolder>
                    <DetailsTable>
                        <DetailsTableName value={name} onChange={async (e) => await onModify({ name: e })} />
                        <DetailsTableRow title="Type:">
                            <ResourceWithIcon type={$type} />
                        </DetailsTableRow>
                        <DetailsTableRow title="Public IPv4:">
                            <ClipBoardTooltip>{address}</ClipBoardTooltip>
                        </DetailsTableRow>
                        <DetailsTableRow title="Status:">
                            {assignedResourceId !== UNASSIGNED_RESOURCE ? (
                                <Badge color="success">Assigned</Badge>
                            ) : (
                                <Badge color="error">Unassigned</Badge>
                            )}
                        </DetailsTableRow>
                        <DetailsTableRow title="Assigned to:">
                            <AssignToSelect
                                enableInlineEditing
                                valueKey={resourceIdToString(assignedResourceId)}
                                location={location}
                                onChange={async (obj) => await assignTo(obj)}
                            />
                        </DetailsTableRow>
                    </DetailsTable>
                    <DetailsTable>
                        <DetailsTableRow title="Created:">
                            <LongDate date={created_at} />
                        </DetailsTableRow>
                        <LocationDetail slug={location} />
                        <DetailsTableRow />
                        <DetailsTableBillingAccount
                            valueKey={billing_account_id}
                            onChange={async (item) => await onModify({ billing_account_id: item.id })}
                        />
                        <MonthlyCostRow price={obj.price} />
                    </DetailsTable>
                </DetailsHolder>
            </ContentPane>
        </>
    );
}
