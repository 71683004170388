import { atomFamily } from "jotai/utils";
import { atom } from "jotai/vanilla";
import { mergeQueryStatuses, type QMergedResult } from "../../../utils/query/mergeQueries.ts";
import { resourceTypeEnabledAtom } from "../../api/resourceTypes.tsx";
import { vmQueryAtom } from "../../compute/vmQuery.ts";
import { allServicesQueryAtom } from "../../services/servicesQuery.ts";
import { lbQueryAtom } from "../loadbalancer/apiOperations.ts";
import type { IpAssignableResource } from "./resourceId.ts";

export const ipAssignableQueryAtom = atomFamily((location: string) =>
    atom((get): QMergedResult<IpAssignableResource[]> => {
        const vms = get(resourceTypeEnabledAtom("virtual_machine")) ? get(vmQueryAtom(location)) : undefined;
        const balancers = get(resourceTypeEnabledAtom("load_balancer")) ? get(lbQueryAtom(location)) : undefined;
        const services = get(resourceTypeEnabledAtom("managed_service")) ? get(allServicesQueryAtom) : undefined;

        const servicesData = services?.data ? [...services.data.values()] : [];

        // Would use `mergeQueries` here, but the query can be `undefined` which it does not support
        return mergeQueryStatuses({ vms, balancers, services }, [
            ...(vms?.data?.values() ?? []),
            ...(balancers?.data?.values() ?? []),
            // NB: services are for all locations, need to add an extra filter here
            ...servicesData.filter((s) => s.location === location),
        ]);
    }),
);
